import React, {ReactElement} from "react";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardHeader,
  Link,
  SvgIconClasses,
  Typography
} from "@mui/material";

// 参考 「Material UIでページ情報を表示するカード型のリンクを作成する」https://financial-programmer.net/blog//mui-blogcard
// 参考 「Material UIで自動で列を調整してくれるグリッドレイアウトを作り、ページネーション機能を付ける」https://financial-programmer.net/blog//mui-grid-layout

type ProductCardProps = {
  appName: string,
  icon?: ReactElement<SvgIconClasses>,
  // description: string,
  link: string,
}

export default function ProductCard(props: ProductCardProps){
  return (
    <Card>
      <CardActionArea
        component={Link}
        href={props.link}
        target={"_blank"} // Open in new tab
      >
        <Box>
          <CardHeader
            title={<Typography>{props.appName}</Typography>}
            subheader={<Typography variant={"caption"} color={"text.secondary"} noWrap={true}> {props.link} </Typography>}
            avatar={
              <Avatar>{props.icon ? props.icon : props.appName[0]}</Avatar>
            }
          />
          {/*<CardContent>*/}
          {/*</CardContent>*/}
        </Box>
      </CardActionArea>
    </Card>
  )
}