import React from 'react';

import MyAppBar from './MyAppBar';
import NavigationBar from "./NavigationBar";
import AppBody from "./AppBody/AppBody";
import {Box, Grid} from "@mui/material";


function App() {
  return (
    <>
      <MyAppBar />

      <Grid container>

        <Grid item xs={0}>
          <NavigationBar />
        </Grid>

        <Grid item xs={12}>
          <Box margin={1}>
            <AppBody />
          </Box>
        </Grid>

      </Grid>

    </>
  );
}

export default App;
