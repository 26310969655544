import {AppBar, Avatar, Typography, Toolbar} from "@mui/material";
export default function MyAppBar() {
  return (
    <AppBar position="static" sx={{backgroundColor: "darkgreen"}}>
      <Toolbar>
        <Avatar src="/index_librorum_prohibitorum_192.png" sx={{margin: 1, marginRight: 3}} />
        <Typography fontSize={28} fontWeight={700} sx={{flexGrow:1}}> showkami </Typography>
        {/* MEMO: flexGrow:1 を指定しておくと、横にうまい具合にコンポーネントを配置してくれるっぽい */}
        {/*<Typography> hogehoge </Typography>*/}
      </Toolbar>

      <AppBar position="static" sx={{height: 8, backgroundColor: "purple"}} />
    </AppBar>

  );
}