import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import ProductCard from "./ProductCard";
import {Map, MusicNote, CurrencyYen} from "@mui/icons-material";

export default function AppBody() {
  return (
    <>
      <Typography fontFamily={'"M PLUS Rounded 1c", sans-serif'}> ▽・ω・▽ b </Typography>

      <Box margin={1}>
        <Typography variant={"h6"}> Apps </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <ProductCard appName={"Geolocation"} link={"https://geolocation.showkami.com"} icon={<Map />} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <ProductCard appName={"Music theory"} link={"https://music.showkami.com"} icon={<MusicNote />} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <ProductCard appName={"Income tax"} link={"https://income-tax.showkami.com/"} icon={<CurrencyYen />} />
          </Grid>
        </Grid>

        <Typography variant={"h6"}> GitHub </Typography>
        <img alt="Most Used Languages" src="https://github-readme-stats.vercel.app/api/top-langs/?username=showkami&theme=dark&show_icons=true&layout=donut" />
        {/* theme は see https://github.com/anuraghazra/github-readme-stats/blob/master/themes/README.md */}
      </Box>

    </>
  )
}